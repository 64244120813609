.screen {
}

.topHome {
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Quicksand", sans-serif;
}

.topHome img {
  width: 70px;
  height: 70px;
}

.topHome .p1 {
  font-weight: 800;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.topHome .p2 {
  font-weight: 800;
  font-size: 14px;
  color: rgb(184, 184, 184);
  padding-top: 0px;
  margin-top: 10px;
}

.topHome .contentimage{
  background-color: white; 
  border-radius: 50%;
  padding: 8px;
  margin-top: -40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.grid {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.sliderHome {
  width: 95%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.sliderContainer {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

.sliderContainer img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Garante o ajuste da imagem */
  border-radius: 20px;
}

.informeDetails {
  position: absolute;
  top: 0; /* Ajuste para cobrir a imagem completamente */
  left: 0;
  right: 0;
  bottom: 0; /* Certifique-se de cobrir toda a imagem */
  background: rgba(0, 0, 0, 0.26); /* Fundo semitransparente */
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
  border-radius: 20px; /* Alinha com a borda da imagem */
  text-align: left;
}

.informeDetails .topico {
  align-items: start;
  background-color: #fff;
  border-radius: 5px;
  color: #d61515;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  padding: 4px 16px;
}

.informeDetails h2,
.informeDetails h3,
.informeDetails p {
  margin: 5px 0; /* Espaçamento entre os elementos */
}

.informeDetails h2 {
  font-size: 1.8rem;
  font-weight: bold;
}

.informeDetails h3 {
  font-size: 1.2rem;
  font-style: italic;
}

.informeDetails p {
  font-size: 18px;
}

.listFunctions {
  overflow-y: auto;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 120px;
  gap: 20px;
  padding-left: 0px;
  padding-right: 10px;
  padding: 20px;
  padding-top: 0px;
}

.listFunctions a {
  text-decoration: none;
  background-color: white;
  border: 1px solid rgb(212, 212, 212);
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  max-width: 150px;
  min-width: 150px;
  height: 80px;
  text-decoration: none;
  color: rgb(43, 43, 43);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  gap: 4px;
  font-size: 15px;
}

.listFunctions a .icon {
  padding-left: 3px;
  font-size: 15px;
  font-weight: 800;
}

.titleHome {
  font-weight: 1000;
  font-size: 18px;
  color: #ec3237;
  padding-left: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 0px;
  margin: 0px;
}

.calendarList {
  width: 90%;
  background-color: white;
  margin-top: 10px;
  border-radius: 10px;
  margin-left: 22px;
  margin-bottom: 30px;
  border: 1px solid rgb(218, 218, 218);
}

.calendarList .content {
  display: flex;
}

.calendarList .content .categoryColor {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 19px;
}

.calendarList .content .conteudo {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.calendarList .content .conteudo .category {
  font-weight: 600;
  text-decoration: underline;
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  color: #ec3237;
  padding-bottom: 5px;
}

.calendarList .content .conteudo .date {
  color: rgb(71, 65, 65);
  text-decoration: none;
  font-size: 13px;
}

.calendarList .content .conteudo .descricao {
  color: rgb(65, 56, 56);
}

.calendarList .content .conteudo h2 {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
}

.noCalendar {
  width: 90%;
  background-color: white;
  margin-top: 15px;
  border-radius: 10px;
  margin-left: 22px;
  margin-bottom: 30px;
}

.noCalendar .top {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 240, 156);
  color: rgb(243, 208, 9);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 600;
}

.noCalendar .bottom {
  height: auto;
  font-weight: 600;
  padding: 20px 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: start;
  text-align: center;
}

@media (max-width: 700px) {
  .informeDetails h2 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .informeDetails p {
    margin: 0px;
    margin-left: 5px;
    font-size: 14px;
  }
  .informeDetails {
    position: absolute;
    top: 0; /* Ajuste para cobrir a imagem completamente */
    left: 0;
    right: 0;
    bottom: 0; /* Certifique-se de cobrir toda a imagem */
    background: rgba(0, 0, 0, 0.26); /* Fundo semitransparente */
    color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: flex-start;
    border-radius: 20px; /* Alinha com a borda da imagem */
    text-align: left;
  }
}
