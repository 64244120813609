.screen {
  height: 100%;
}

.backgroundTop {
  width: 100%;
  height: 370px;
  border-bottom: solid 4px #ffde59;
}

.backgroundTop img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/*  */
.perfil {
  min-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.imageContainer {
  position: relative;
  width: 100px;
  height: 100px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ddd;
}

.cameraIcon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.cameraIcon svg {
  font-size: 14px;
  color: #333;
}

.cameraIcon:hover {
  background-color: #f0f0f0;
}

.details {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.editIcon {
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.editIcon:hover {
  color: #555;
}

.role {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

/*  */
.listFunctions {
  display: grid;
  grid-template-columns: repeat(
    4,
    1fr
  ); /* Creates a grid with 2 columns of equal width */
  justify-content: center;
  gap: 20px;
  padding: 30px 10px 20px 10px;
  overflow-y: auto;
  max-height: 50%;
}

.listFunctions a {
  text-decoration: none;
  background-color: white;
  border: 1px solid rgb(212, 212, 212);
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  max-width: 150px;
  min-width: 75px;
  height: 80px;
  text-decoration: none;
  color: rgb(43, 43, 43);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  gap: 4px;
  font-size: 15px;
}

.listFunctions a .icon {
  padding-left: 3px;
  font-size: 15px;
  font-weight: 800;
}

@media (max-width: 900px) {
  .listFunctions {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 700px) {
  .listFunctions {
    grid-template-columns: repeat(2, 1fr);
  }
}
