/* Contêiner principal */
.notificacoesContainer {
  width: 95%;
  height: 100%;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Lista de Notificações */
.listaNotificacoes {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Cada item da notificação */
.notificacaoItem {
  padding: 20px;
  border-radius: 0px;
  background: #ffffff;
  border-left: 5px solid #ec3237;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #ddd;
}

.notificacaoItem:hover {
  transform: scale(1.005);
  background: #f9f9f9;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.notificacaoItem p {
  border-left: 3px solid #dc3545;
  background-color: #ffcdd2;
  padding: 20px 10px;
  font-weight: 500;
  color: #970312;
}

/* Cabeçalho da notificação */
.cabecalho {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Tipo da Notificação */
.tipo {
  padding: 6px 0px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  color: #dc3545;
}

/* Estilização para cada tipo de notificação */
.Informativo {
  background: #e3f2fd;
  color: #1565c0;
}

.Importante {
  background: #fff3cd;
  color: #856404;
}

.Urgente {
  background: #f8d7da;
  color: #721c24;
}

/* Rodapé da notificação */
.rodape {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

/* Ícones de prioridade */
.prioridadeBaixa {
  color: #28a745;
  font-size: 20px;
}

.prioridadeMediaBaixa {
  color: #ffc107;
  font-size: 20px;
}

.prioridadeMedia {
  color: #ff9800;
  font-size: 20px;
}

.prioridadeAlta {
  color: #dc3545;
  font-size: 20px;
}

.prioridadeMuitoAlta {
  color: #8b0000;
  font-size: 22px;
}

.prioridadeUrgente {
  color: #ff0000;
  font-size: 22px;
}

.naoVisualizado {
  background-color: rgba(255, 0, 0, 0.041); /* Vermelho bem suave */
}

.sliderContainer {
  position: relative;
  text-align: center;
  width: 100%;
  padding-top: 40%;
}

/* Responsividade */
@media (max-width: 768px) {
  .notificacaoItem {
    padding: 15px;
  }

  .rodape {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}
