.sliderContainer {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

.horariosList {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.daysContainer {
  display: flex;
  overflow-x: auto;
  /* Permite rolagem horizontal */
  width: 98%;
  /* Ocupa toda a largura */
  padding: 10px 0;
  padding-bottom: 20px;
  scroll-behavior: smooth;
  gap: 10px;
}

.daysContainer button {
  min-width: 140px;
  border: 1px solid #eb3237;
  padding: 5px 2px;
  border-radius: 5px;
  background-color: white;
  color: #eb3237;
  font-weight: 600;
  font-size: 15px;
}

.daysContainer .selected {
  background-color: #eb3237;
  color: white;
}

.horarioItem .titulo {
  width: 98%;
  font-size: 20px;
  color: rgb(66, 58, 58);
  border-bottom: 2px solid #d3d3d3;
}

/* Semana */
.semana_all {
  width: 98%;
}

.semana_all .semana {
  display: flex;
  justify-content: start;
  align-items: end;
  color: gray;
  padding-top: 5px;
  padding-bottom: 10px;
}

.semana_all .semana strong {
  font-size: 18px;
}

.semana_all .semana p {
  margin: 0px;
  padding-bottom: 2.8px;
  padding-left: 5px;
}

.semana_all .horario {
  border-left: 2px solid #eb3237;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.semana_all .horario_top {
  display: flex;
  justify-content: start;
  align-items: end;
  padding: 4px;
}

.semana_all .horario_bottom {
  width: 80%;
  margin-left: 20px;
}
