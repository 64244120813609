.screen {
  height: 100%;
}

.AllScreen {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content {
  width: 100%;
  height: 100%;
  order: 1;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  background: linear-gradient(45deg, #f6ca3b, #f6ca3b, #ec3237, #ec3237);
  background-size: 400% 400%; /* Faz o background "maior" para o movimento */
  animation: gradientAnimation 10s ease infinite;
}

/* Definindo a animação */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content .element {
  height: 70%;
  width: 100%;
  margin-top: 90px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff8f9;
}

.menu {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 50px;
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuitems {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: initial;
  gap: 15%;
  position: fixed;
  bottom: 0px;
  right: 0px;
  background-color: rgb(255, 255, 255);
  border: none;
}

.menuitems .selected {
  background-color: #ec323886;
  color: #ec3237;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 35px;
  border-radius: 5px;
}

.menuitems a {
  font-size: 18px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  border-radius: 5px;
  color: #ec3237;
}

.menuitems .selected a {
  color: #ec3237;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  border-radius: 5px;
}

/* Bolinha de notificação */
.notificacaoBolinha {
  position: absolute;
  top: 0px;
  right: -4px;
  width: 10px;
  height: 10px;
  background-color: rgb(255, 217, 46);
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Ajustando os links do menu */
.menuLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 600px) {
  .AllScreen {
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .content {
    width: 100%;
    height: 90%;
    order: 1;
  }

  .content .element {
    height: 100%;
    width: 100%;
  }

  .menu {
    width: 100%;
    height: 50px;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
  }

  .menuitems {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: initial;
    gap: 15%;
    position: fixed;
    bottom: 0px;
    right: 0px;
    background-color: rgb(255, 255, 255);
    border: none;
  }

  .menuitems .selected {
    background-color: #ec323886;
    color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 35px;
    border-radius: 5px;
  }

  .menuitems a {
    color: #ec3237;
    font-size: 23px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
    border-radius: 5px;
  }

  .menuitems .selected a {
    color: #ec3237;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
    border-radius: 5px;
  }
}
