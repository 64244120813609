.screen {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Quicksand", sans-serif;
}

.screen .TopArea {
    background-color: #ec3237;
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen .TopArea .right {
    width: 10%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
}

.screen .TopArea .right a {
    text-decoration: none;
    color: yellow;
    font-family: "Quicksand", sans-serif;
}

.screen .TopArea .center {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: yellow;
    font-weight: 600;
    font-size: 18px;
}

.screen .TopArea .left {
    width: 10%;
    height: 100%;
    color: #ec3237;
}

.screen .BottomArea {
    background-color: #fff8f9;
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.monthsContainer {
    display: flex;
    overflow-x: auto;
    /* Permite rolagem horizontal */
    width: 100%;
    /* Ocupa toda a largura */
    padding: 10px 0;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    scroll-behavior: smooth;
    /* Rola suavemente */
}

.monthButton {
    padding: 8px 16px;
    font-size: 14px;
    min-width: 90px;
    /* Define uma largura mínima para cada botão */
    border: none;
    border-radius: 8px;
    background-color: #e0e0e0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    margin-left: 5px;
    margin-right: 5px;
    /* Evita quebra de linha nos nomes dos meses */
}

.monthButton:hover {
    background-color: #ec3237;
    color: white;
}

.selected {
    background-color: #ec3237;
    color: white;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(236, 50, 55, 0.4);
}


.ListInformes {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: 100%;
    gap: 20px;
    padding-top: 30px;
}

.informeItem {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    overflow-y: auto;
    border-left: 3px solid #ec3237;
    border-radius: 3px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 20px;
    cursor: pointer;
}

.informeItem .img {
    width: 100px;
    height: 110px;
    border-radius: 10px;
}

.informeItem .img img{
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.informeItem .conteudo {
    width: 100%;
    gap: 10px;
}
.informeItem .conteudo .topico{
    font-weight: 100;
    font-size: 12px;
    color: gray;
}
.informeItem .conteudo .titulo{
    font-weight: 600;
    font-size: 18px;
    color: rgb(27, 26, 26);
}
.informeItem .conteudo .desc{
    font-weight: 100;
    font-size: 11px;
    color: gray;
}

.informeItem .conteudo .date{
    font-weight: 300;
    font-size: 13px;
    color: rgb(100, 100, 100);
}

@media (max-width: 700px) {
    .ListInformes {
        width: 80%;
    }

    .InformesMonth {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 10px 20px 10px;
        overflow-x: auto; /* Permite rolagem horizontal */
    }
}
