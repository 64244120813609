.screen {
    height: 100%;
}

.topHome {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Quicksand", sans-serif;
}
.topHome img{
    width: 80px;
    height: 80px;
}
.topHome p{
    font-weight: 600;
}

.grid {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.sliderHome {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.sliderContainer {
    height: 200px;
}

.sliderContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}

.listFunctions {
    overflow-y: auto;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 120px;
    gap: 20px;
    padding-left: 0px;
    padding-right: 10px;
    padding: 20px;
}

.listFunctions a {
    text-decoration: none;
    background-color: white;
    border: 1px solid rgb(212, 212, 212);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    max-width: 150px;
    min-width: 150px;
    height: 80px;
    text-decoration: none;
    color: rgb(43, 43, 43);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    gap: 4px;
    font-size: 15px;
}

.listFunctions a .icon {
    padding-left: 3px;
    font-size: 15px;
    font-weight: 800;
}