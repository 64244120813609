.container {
    background-color: rgba(219, 219, 219, 0.733);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    background-color: white;
    width: 65%;
    height: 65%;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    
}

.form div{
    width: 50%;
}


.form div .send {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.send input {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: solid 0.5px rgb(211, 211, 211);
    padding: 5px;
}

.send input:focus {
    outline: none;
    border: solid 0.7px rgb(173, 173, 173);
}

.send button {
    width: 90%;
    height: 40px;
    margin-top: 10px;
    border: none;
    color: white;
    font-weight: 700;
    font-size: 13px;
    border-radius: 40px;
}

.buttonDisabled {
    background-color: #DDDDDD;
    cursor: not-allowed;
}

.buttonEnabled {
    background-color: #ffdd58;
    cursor: pointer;
}

.form div p{
    font-weight: 600;
    color: gray;
    font-size: 16px;
}

.form div strong{
    font-weight: 600;
    color: rgb(71, 71, 71);
    font-size: 16px;
}

.form .img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow-x: auto;
}

.form .img img{
    width: 300px;
    height: 70%;
}

@media (max-width:700px) {
    .form {
        width: 100%;
        height: 100%;
        padding: 20px;
    }
    .form .img{
        display: none;
    }
    .form div{
        width: 90%;
    }
}