.atividadeContainer {
    background-color: rgb(255, 250, 250);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.topArea {
    width: 90%;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 30px;
}

.topArea a{
    text-decoration: none;
    color: #ec3237;
    font-size: 25px;
    
}

.actionsAtv {
    width: 100%;
    height: 30px;
    padding: 10px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: #ec3237;
    display: flex;
    justify-content:space-between;
    border-top: 2px solid yellow;
}

.actionsAtv div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 30%;
}

.actionsAtv div button{
    padding: 5px 10px 5px 10px;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    border: none;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    background-color: #a89d9d;
    color: white;
}

.actionsAtv div .send{
    background-color: #ffde59;
    padding: 8px 13px 8px 13px;
}

.atividadeContent {
    width: 90%;
}

.atividadeContent h1 {
    color: #ac2428;
}

.atividadeContent .opcoes {
    gap: 10px;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.actionsAtv button {
    margin: 5px;
}

.send {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.send.disabled {
    background-color: grey;
    cursor: not-allowed;
}



.ConfirmStartAtv {
    background-color: rgba(0, 0, 0, 0.37);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popUpConfirmAtv {
    background-color: white;
    border-radius: 5px;
    width: 50%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popUpConfirmAtv .contentClose {
    width: 97%;
    color: #949494;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 21px;
}


.popUpConfirmAtv .alert{
    color: rgb(252, 210, 25);
    font-size: 45px;
}

.popUpConfirmAtv hr {
    width: 80%;
}

.contentConfirm {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contentConfirm .title {
    color: #921c20;
    font: bold 12pt "Quicksand", sans-serif;
    width: 90%;
    text-align: center;
}
.contentConfirm .description {
    color: #949494;
    width: 85%;
    text-align:center;
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 5px;
}

.contentConfirm .action {
    width: 90%;
    margin-top: 15px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentConfirm .action a {
    text-decoration: none;
    background-color: rgb(172, 172, 172);
    padding: 4px 14px 4px 14px;
    border: solid 1px rgb(233, 233, 233);
    border-radius: 3px;
    cursor: pointer;
    margin-left: 10px;
    font: bold 9pt "Quicksand", sans-serif;
    font-weight: 800;
    color: rgb(238, 238, 238);
}


@media (max-width:600px) {
    .actionsAtv div{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
    }
    .popUpConfirmAtv {
        width: 90%;
    }
    .contentConfirm {
        width: 100%;
    }
    .contentConfirm .action a {
        padding: 3px 11px 3px 11px;
        border: solid 1px rgb(233, 233, 233);
        font: bold 8pt "Quicksand", sans-serif;
        font-weight: 800;
    }
    .contentConfirm .title {
        color: #921c20;
        font: bold 10pt "Quicksand", sans-serif;
        width: 90%;
    }
    .contentConfirm .description {
        font-size: 11px;
    }
}