.atividadesScreen {
    background-color: #ec3237;
    width: 100%;
    height: 100vh;
}

.top {
    padding-top: 100px;
    padding-bottom: 100px;
    height: 10%;
    color: #ffde59;
    display: flex;
    justify-content: space-between;
    /* Para alinhar os itens no início e no final */
    align-items: center;
    padding: 0 20px;
    /* Adiciona um espaço interno para evitar que o texto encoste nas bordas */
    font-size: 20px;
    font-weight: 600;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.title {
    margin: 0;
    /* Remove a margem padrão do parágrafo */
    text-align: center;
    /* Centraliza o texto */
}

.backLink {
    color: #ffde59;
    text-decoration: none;
    /* Remove a decoração do link */
    display: flex;
    /* Para alinhar o ícone e o texto verticalmente */
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    transition: 1s;
    border-radius: 50%;
}

.backLink:hover {
    border-radius: 50%;
    color: #ffcd05;
}

.content {
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.all {
    height: 100%;
    width: 70%;
}

.all .item {
    background-color: white;
    height: 80px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 2px;
    border-radius: 10px;
}

.all .item .sec1 {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #ffde59;
}

.all .item .sec2 {
    width: 60%;
}

.all .item .sec2 .tit {
    color: #921c20;
    font-weight: 600;
    font-size: 15px;
}

.all .item .sec2 .mat {
    color: #949494;
    font-weight: 400;
    font-size: 12px;
}

.all .item .sec3 {
    width: 30%;
}

.all .item .sec3 .nota {
    color: gray;
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    height: 30px;
    gap: 6px;
}

.all .item .sec3 .nota p{
    height: 100%;
    color: #ffde59;
    font-weight: 800;
    font-size: 28px;
    margin: 0;
    padding: 0;
}

.all .item .sec3 .play {
    text-decoration: none;
    width: 45px;
    height: 45px;
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffde59;
    border-radius: 50%;
    cursor: pointer;
    transition: 1s;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid rgb(105, 105, 105);
}

.all .item .sec3 .play:hover {
    background-color: #921c20;
}

.choseList {
    padding-left: 5%;
    padding-top: 20px;
    padding-bottom: 10px;
    color: white;
    font-weight: 800;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.choseList div {
    cursor: pointer;
}

.ConfirmStartAtv {
    background-color: rgba(0, 0, 0, 0.37);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popUpConfirmAtv {
    background-color: white;
    border-radius: 5px;
    width: 50%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popUpConfirmAtv .contentClose {
    width: 97%;
    color: #949494;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 21px;
}


.popUpConfirmAtv .alert{
    color: rgb(252, 210, 25);
    font-size: 45px;
}

.popUpConfirmAtv hr {
    width: 80%;
}

.contentConfirm {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contentConfirm .title {
    color: #921c20;
    font: bold 12pt "Quicksand", sans-serif;
    width: 90%;
    text-align: center;
}
.contentConfirm .description {
    color: #949494;
    width: 85%;
    text-align:center;
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 5px;
}

.contentConfirm .action {
    width: 90%;
    margin-top: 15px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentConfirm .action a {
    text-decoration: none;
    background-color: rgb(172, 172, 172);
    padding: 4px 14px 4px 14px;
    border: solid 1px rgb(233, 233, 233);
    border-radius: 3px;
    cursor: pointer;
    margin-left: 10px;
    font: bold 9pt "Quicksand", sans-serif;
    font-weight: 800;
    color: rgb(238, 238, 238);
}

@media (max-width:600px) {
    .all {
        width: 90%;
    }
    .popUpConfirmAtv {
        width: 90%;
    }
    .contentConfirm {
        width: 100%;
    }
    .contentConfirm .action a {
        padding: 3px 11px 3px 11px;
        border: solid 1px rgb(233, 233, 233);
        font: bold 8pt "Quicksand", sans-serif;
        font-weight: 800;
    }
    .contentConfirm .title {
        color: #921c20;
        font: bold 10pt "Quicksand", sans-serif;
        width: 90%;
    }
    .contentConfirm .description {
        font-size: 11px;
    }
    
}