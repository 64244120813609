.all {
  display: flex;
  justify-content: center;
}

.bottomAreaItem {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.eventDay {
  color: inherit;
  /* Isso permitirá que a cor seja herdada do estilo inline */
}

.calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 20px;
  background-color: rgb(235, 235, 235);
  border: 1px solid gray;
}

.calendarWrapper {
  width: 95%;
}

.reactCalendar {
  width: 100% !important;
  border-radius: 10px;
  padding: 5px;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  border: solid 0.5px rgba(230, 230, 230, 0.89);
}

.calendar {
  width: 95%;
  border-radius: 10px;
  border: solid 0.5px rgba(230, 230, 230, 0.89);
  padding-top: 5px;
  padding-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categories {
  width: 95%;
  border-radius: 10px;
  border: solid 0.5px rgba(230, 230, 230, 0.89);
  padding-top: 5px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categories h2 {
  width: 90%;
  font-size: 17px;
  font-weight: 700;
  color: rgb(77, 77, 77);
}

.categories .item {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 85%;
  gap: 10px;
  border-bottom: solid 0.3px rgba(226, 226, 226, 0.5);
  padding-bottom: 15px;
  padding-top: 10px;
  cursor: pointer;
}

.categories .item .colorBoll {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.categories .item .nameCat {
  color: rgb(168, 168, 168);
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 700px) {
  .home {
    width: 100%;
    height: 100%;
  }

  .topAreaInfo {
    flex-direction: column;
    align-items: start;
    width: 95%;
    height: auto;
    gap: 10px;
  }

  .topAreaInfo .end {
    justify-content: start;
    width: 100%;
    justify-content: start;
  }
}
