.screen {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Quicksand", sans-serif;
}

.screen .TopArea {
    background-color: #ec3237;
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen .TopArea .right {
    width: 10%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
}

.screen .TopArea .right a {
    text-decoration: none;
    color: yellow;
    font-family: "Quicksand", sans-serif;
}


.screen .TopArea .center {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: yellow;
    font-weight: 600;
    font-size: 18px;
}

.screen .TopArea .left {
    width: 10%;
    height: 100%;
    color: #ec3237;
}


.reactCalendar {
    width: 100% !important;
    border-radius: 10px;
    padding: 5px;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    border: solid 0.5px rgba(238, 238, 238, 0.63);
}

.reactCalendar .highlight {
    position: relative;
    height: 100%;
}

.reactCalendar .highlight .eventMarker {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 3px;
    background-color: #ffde59; 
}

.listAgenda {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    padding-top: 20px;
}

.calendarWrapper {
    width: 80%;
}

/* AgendaAluno.module.css */

.reactCalendar {
    border: none;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    padding: 10px;
  }
  
  .reactCalendar .react-calendar__tile {
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ddd;
    transition: all 0.2s ease-in-out;
  }
  
  .reactCalendar .react-calendar__tile:hover {
    background-color: #f0f0f0;
    border-color: #4caf50;
  }
  
  .reactCalendar .react-calendar__tile--active {
    background-color: #4caf50;
    color: #fff;
    font-weight: bold;
    border: none;
  }
  
  .reactCalendar .react-calendar__navigation button {
    background: transparent;
    border: none;
    color: #4caf50;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .reactCalendar .react-calendar__navigation button:hover {
    color: #388e3c;
  }
  
  .reactCalendar .react-calendar__tile--hasActive {
    border-color: #4caf50;
  }
  
  .eventMarker {
    width: 8px;
    height: 8px;
    background-color: #ff5722;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 5px;
  }
  


@media (max-width:700px) {
    .calendarWrapper {
        width: 95%;
    }
}



