.allScreen {
  width: 95%;
  background-color: white;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid rgb(224, 224, 224);
}

.allScreen .content {
  display: flex;
}

.allScreen .content .categoryColor {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 19px;
}

.allScreen .content .conteudo {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.allScreen .content .conteudo .category{
  font-weight: 600;
  text-decoration: underline;
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  color: #EC3237;
  padding-bottom: 5px;
}

.allScreen .content .conteudo .date{
  color: rgb(71, 65, 65);
  text-decoration: none;
  font-size: 13px;
}

.allScreen .content .conteudo .descricao {
  color: rgb(65, 56, 56);
}

.allScreen .content .conteudo h2{
  margin: 0px;
  padding: 0px;
  font-size: 18px;
}