.allScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  z-index: 1000;
}

.content {
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 20px;
  width: 100%;
  height: 40%;
  position: relative;
  animation: slideIn 0.5s ease-out forwards;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: gray;
  transition: 0.3s;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton:hover {
  background-color: rgba(221, 221, 221, 0.2);
}

.notificacaoDetalhes {
  text-align: center;
}

.notificacaoDetalhes h2 {
  color: #570000;
  margin-bottom: 10px;
  text-align: center;
}

.notificacaoDetalhes p {
  color: rgb(59, 59, 59);
  margin-bottom: 15px;
}

.data {
  font-size: 14px;
  color: gray;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.slideOut {
  animation: slideOut 0.5s ease-out forwards;
}
/* Contêiner das interações */
.interacoes {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

/* Botões de interação */
.botaoInteracao {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

/* Efeito ao passar o mouse */
.botaoInteracao:hover {
  transform: scale(1.1);
}

/* Efeito ao clicar */
.botaoInteracao:active {
  transform: scale(0.95);
}

/* Estilo específico para "Curtir" */
.botaoInteracao:first-child {
  background-color: #2196f3; /* Azul */
  color: white;
}

/* Estilo específico para "Amar" */
.botaoInteracao:last-child {
  background-color: #e91e63; /* Rosa */
  color: white;
}

/* Quando o botão está desativado */
.botaoInteracao:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  transform: none;
}

.sliderContainer {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

/* Responsividade */
@media (max-width: 600px) {
  .interacoes {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .botaoInteracao {
    width: 80%;
    font-size: 14px;
  }
}
