.screen {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Quicksand", sans-serif;
}

.TopArea {
    background-color: #ec3237;
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopArea .right,
.TopArea .center,
.TopArea .left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: yellow;
    font-weight: 600;
    font-size: 18px;
}

.TopArea .right a {
    text-decoration: none;
    color: yellow;
}

.listCardapio {
    width: 100%;
    min-height: 70%;
    max-height: 100%;
    overflow-y: auto;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.monthsContainer {
    display: flex;
    overflow-x: auto;
    /* Permite rolagem horizontal */
    width: 100%;
    /* Ocupa toda a largura */
    padding: 10px 0;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    scroll-behavior: smooth;
    /* Rola suavemente */
}

.monthButton {
    padding: 8px 16px;
    font-size: 14px;
    min-width: 90px;
    /* Define uma largura mínima para cada botão */
    border: none;
    border-radius: 8px;
    background-color: #e0e0e0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    margin-left: 5px;
    margin-right: 5px;
    /* Evita quebra de linha nos nomes dos meses */
}

.monthButton:hover {
    background-color: #ec3237;
    color: white;
}

.selected {
    background-color: #ec3237;
    color: white;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(236, 50, 55, 0.4);
}


.table_all {
    width: 87%;
    background-color: #FFFFFF;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid rgb(197, 197, 197);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    font-family: "Quicksand", sans-serif;
    padding: 20px;
}

.table_all h2 {
    border-bottom: 4px solid red; /* Define a borda inferior vermelha */
}

.action_buttons {
    display: flex;
    justify-content: end;
    padding-right: 40px;
    align-items: center;
    height: 10%;
    width: 97%;
    gap: 10px;
}

.action_buttons button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #7a0509;
    background-color: #ff8387ee;
    font-size: 15px;
    transition: 1s;
    cursor: pointer;
}

.action_buttons button:hover {
    color: #7a0509b7;
    background-color: #ff83879d;
}

.action_buttons .updateButton {
    background-color: #fff383ee;
    color: #665d07ee;
}


.action_buttons .updateButton:hover {
    background-color: #dacf70ee;
}

.action_buttons .downloadExcelButton {
    background-color: #175935;
    color:  #31BE7D;
}
.action_buttons .downloadExcelButton:hover {
    background-color: #175935d5;
    color:  #31BE7D;
}

.tableOverflow {
    max-width: 90%;
    overflow-x: auto; /* Permite rolagem horizontal */
    overflow-y: visible; /* Permite que a altura expanda automaticamente */
    background-color: #FFFFFF;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(197, 197, 197);
    min-height: 400px;
}

.table {
    width: 100%;
    border-collapse: collapse; /* Remove espaços entre células */
}

.table th, .table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Linha de divisão discreta */
}

.table th {
    background-color: #EC3237; /* Cabeçalho com cor mais forte */
    color: white; /* Texto branco no cabeçalho */
    text-transform: uppercase; /* Texto em maiúsculas para o cabeçalho */
    font-weight: bold;
    min-width: 200px;
}

.table tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Cor alternada para linhas pares */
}

.table tbody tr:hover {
    background-color: #e9ecef; /* Cor de destaque ao passar o mouse */
    cursor: pointer;
}

.table .refeicao {
    font-weight: bold; /* Destaca a coluna de refeições */
}

.itemList li {
    margin-left: 10px;
    padding: 8px 12px; /* Espaçamento interno */
    background-color: #f1f1f1; /* Cor de fundo suave */
    border-radius: 6px; /* Bordas arredondadas */
    list-style-type: none; /* Remove o marcador padrão */
    position: relative; /* Para posicionar o novo marcador */
    font-size: 0.9rem; /* Ajusta o tamanho da fonte */
    color: #333; /* Cor do texto */
    margin-bottom: 6px; /* Espaçamento entre os itens */
}

.itemList li::before {
    content: '•';
    position: absolute;
    left: -15px;
    color:#007bff; /* Usando a cor de categoria dinâmica */
    font-size: 1.2rem;
    top: 4px;
}

.pagination {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* Estilos para o bloco de observação */
.observacao {
    width: 90%;
    background-color: #f4f4f9;
    /* Fundo suave para destacar a área */
    border-left: 4px solid #ec3237;
    /* Barra lateral colorida para destaque */
    padding: 10px 15px;
    margin-top: 15px;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    line-height: 1.6;
}

/* Estilos para o texto em negrito */
.observacao strong {
    color: #ec3237;
    font-weight: bold;
}


@media (max-width: 700px) {
    .monthsContainer {
        width: 100%;
    }

    .table_all {
        min-width: 70%;
        border-radius: 10px;
        align-items: start;
    }

    .table_all h2 {
        font-size: 20px;
        border-bottom: 2px solid red; /* Define a borda inferior vermelha */
        padding-bottom: 5px; /* Espaço entre o texto e a borda */
    }
    

    .table {
        min-width: 800px;
    }
}