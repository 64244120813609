.allScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* Alinhar na parte inferior da tela */
    overflow: hidden;
    z-index: 1000;
}

.content {
    background: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 20px;
    width: 100%;
    height: 90%;
    position: relative;
    animation: slideIn 0.5s ease-out forwards;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header button {
    font-size: 25px;
    color: gray;
    transition: 1s;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header button:hover {
    background-color: rgba(221, 221, 221, 0.212);
}

.closeButton {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.eventsList {
    height: 100%;
}

.materias {
    max-height: 80%;
    overflow-y: auto;
}

.event {
    width: 50%;
    margin-bottom: 15px;
    padding-left: 10px;
    border-radius: 1px;
    padding-bottom: 15px;
    padding-top: 5px;
}

.event h3 {
    color: #570000;
}

.event h4 {
    color: #a32328;
}

.event p {
    color: rgb(59, 59, 59);
}

.event .event1 {
    display: flex;
}

.event .event1 div {
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.event .event2 {
    display: flex;
}

.event .event2 div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.event .event3 {
    display: flex;
}

.event .event3 div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.event4 {
    width: 80%;
}

.materiaCampo {
    width: 100%;
    border: 1px solid rgb(196, 196, 196);
    padding: 5px;
    min-height: 40px;
    border-radius: 5px;
}

.event h3 {
    margin: 0 0 5px;
}

.event p {
    margin: 0;
}

.Display {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    padding-bottom: 20px;
}

.dateDisplay {
    width: 50px;
    height: 50px;
    color: #ec3237;
    background-color: #ff989c;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
}

.dateDisplay .day {
    font-weight: 700;
}

.dateDisplay .month {
    font-weight: 500;
    font-size: 11px;
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
        /* Começar fora da tela abaixo */
        opacity: 0;
    }

    to {
        transform: translateY(0);
        /* Terminar na posição final */
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
        /* Começar na posição final */
        opacity: 1;
    }

    to {
        transform: translateY(100%);
        /* Terminar fora da tela abaixo */
        opacity: 0;
    }
}

.slideOut {
    animation: slideOut 0.5s ease-out forwards;
}

@media (max-width:900px) {
    .event {
        width: 80%;
        margin-bottom: 15px;
    }
}
@media (max-width:700px) {
    .event {
        width: 100%;
        margin-bottom: 15px;
    }
}