.screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Quicksand", sans-serif;
}

.screen .TopArea {
  background-color: #ec3237;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen .TopArea .right {
  width: 10%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
}

.screen .TopArea .right a {
  text-decoration: none;
  color: yellow;
  font-family: "Quicksand", sans-serif;
}

.screen .TopArea .center {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: yellow;
  font-weight: 600;
  font-size: 18px;
}

.screen .TopArea .left {
  width: 10%;
  height: 100%;
  color: #ec3237;
}

.reactCalendar {
  width: 100% !important;
  border-radius: 10px;
  padding: 5px;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  border: solid 0.5px rgba(238, 238, 238, 0.63);
}

.reactCalendar .highlight {
  position: relative;
  height: 100%;
}

.reactCalendar .highlight .eventMarker {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: #ffde59;
}

.listAgenda {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 0px;
}

.calendarWrapper {
  width: 80%;
}

/* AgendaAluno.module.css */

.reactCalendar {
  border: none;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  font-size: 14px;
  padding: 10px;
}

.reactCalendar .react-calendar__tile {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #ddd;
  transition: all 0.2s ease-in-out;
}

.reactCalendar .react-calendar__tile:hover {
  background-color: #f0f0f0;
  border-color: #4caf50;
}

.reactCalendar .react-calendar__tile--active {
  background-color: #4caf50;
  color: #fff;
  font-weight: bold;
  border: none;
}

.reactCalendar .react-calendar__navigation button {
  background: transparent;
  border: none;
  color: #4caf50;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.reactCalendar .react-calendar__navigation button:hover {
  color: #388e3c;
}

.reactCalendar .react-calendar__tile--hasActive {
  border-color: #4caf50;
}

.eventMarker {
  width: 8px;
  height: 8px;
  background-color: #ff5722;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 5px;
}

.filters {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 80%;
    margin: 20px 0;
    gap: 20px;
    padding-top: 20px;
    
}

.filterGroup {
    margin-bottom: 15px;
    text-align: left;
    width: 30%;
}

.filterGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #b4282c;
}

.filterGroup select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #fff;
    appearance: none;
    cursor: pointer;
}

.filterGroup select[multiple] {
    height: auto;
    min-height: 50px;
    overflow-y: auto;
}

.categories {
  width: 95%;
  border-radius: 10px;
  border: solid 0.5px rgba(230, 230, 230, 0.89);
  padding-top: 5px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categories h2 {
  width: 90%;
  font-size: 17px;
  font-weight: 700;
  color: rgb(77, 77, 77);
}

.categories .item {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 85%;
  gap: 10px;
  border-bottom: solid 0.3px rgba(226, 226, 226, 0.5);
  padding-bottom: 15px;
  padding-top: 10px;
  cursor: pointer;
}

.categories .item .colorBoll {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.categories .item .nameCat {
  color: rgb(168, 168, 168);
  font-size: 14px;
  font-weight: 500;
}

.category {
  margin-bottom: 10px;
  width: 90%;
  gap: 10px;
}

.category span {
  padding-left: 10px;
  color: 2px solid rgb(66, 59, 59);
  font-weight: 500;
}


@media (max-width: 700px) {
  .calendarWrapper {
    width: 95%;
  }
  .filters {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 95%;
    margin: 20px 0;
}
}
