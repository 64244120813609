.screen {
    height: 100%;
}

.backgroundTop {
    width: 100%;
    height: 370px;
    border-bottom: solid 4px #ffde59;
}

.backgroundTop img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.listFunctions {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates a grid with 2 columns of equal width */
    justify-content: center;
    gap: 20px;
    padding: 30px 10px 20px 10px;
    overflow-y: auto;
    max-height: 50%;
}

.listFunctions a {
    text-decoration: none;
    background-color: white;
    border: 1px solid rgb(212, 212, 212);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    max-width: 150px;
    min-width: 75px;
    height: 80px;
    text-decoration: none;
    color: rgb(43, 43, 43);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    gap: 4px;
    font-size: 15px;
}

.listFunctions a .icon {
    padding-left: 3px;
    font-size: 15px;
    font-weight: 800;
}

@media (max-width:900px) {
    .listFunctions {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width:700px) {
    .listFunctions {
        grid-template-columns: repeat(2, 1fr);
    }
}