.screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Quicksand", sans-serif;
}

.screen .TopArea {
  background-color: #ec3237;
  height: 100%;
  max-height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen .TopArea .right {
  width: 10%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}

.screen .TopArea .right a {
  text-decoration: none;
  color: yellow;
  font-family: "Quicksand", sans-serif;
}

.screen .TopArea .center {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: yellow;
  font-weight: 600;
  font-size: 18px;
}

.screen .TopArea .left {
  width: 10%;
  height: 100%;
  color: #ec3237;
}

.listPage {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}